import { usePromotionsQuery } from '@/core/graphql/queries/Promotions/Promotions.delio.generated';
import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';
import { Media } from '@lib/theme/components/Media';

import { PromotionsNav } from './PromotionsNav';
import { ReadOnlySlotsPopup } from './ReadOnlySlotsPopup/ReadOnlySlotsPopup';

export const PromotionNavbar: FC = () => {
  const { coordinates, loading: isLoading } = useUserAddressCoordinates();
  const { loading } = usePromotionsQuery({
    variables: { coordinates },
    skip: isLoading,
  });

  return (
    <div className={cn('justify-center', 'bg-gray-050')}>
      <div
        className={cn(
          !loading && cn('max-w-8xl', 'px-5'),
          'h-11',
          'lg:h-10',
          'flex',
          'items-center',
          'justify-center',
          'gap-2',
          'w-full',
          'm-auto'
        )}
      >
        {loading ? (
          <div
            className={cn('bg-gray-200', 'animate-pulse', 'w-full', 'h-full')}
          />
        ) : (
          <div
            className={cn('text-gray-800', 'flex', 'justify-between', 'w-full')}
          >
            <PromotionsNav />
            <Media greaterThanOrEqual="lg">
              <ReadOnlySlotsPopup />
            </Media>
          </div>
        )}
      </div>
    </div>
  );
};
